import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { AsyncPipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-input-select',
  standalone: true,
  imports: [
    AsyncPipe,
    ReactiveFormsModule,
    NgIf,
    CdkVirtualScrollViewport,
    CdkVirtualForOf,
    CdkFixedSizeVirtualScroll,
    NgTemplateOutlet,
    NgClass
  ],
  templateUrl: './input-select.component.html',
  styleUrl: './input-select.component.sass'
})
export class InputSelectComponent<T> implements OnInit, OnChanges {
  @Input() options: T[] = [];
  @Input() entity: T = null;
  @Input() template: TemplateRef<{ $implicit: T }> = null;
  @Input() selectControl: FormControl<T> = new FormControl();
  @Input() label: string = 'Select an entity';
  @Input() disabled: boolean = false;

  @Output() selectChange: EventEmitter<T> = new EventEmitter();

  @ViewChild('dropDownContainer', { read: ViewContainerRef, static: true }) dropDownContainer: ViewContainerRef;
  @ViewChild('selectInput', { read: ViewContainerRef, static: true }) selectInput: ViewContainerRef;

  @HostListener('document:click', ['$event'])
  clickOut($event: Event) {
    if (
      this.isOpen
      && !this.selectInput.element.nativeElement.contains($event.target)
    ) {
      this.close();
    }
  }

  searchControl = new FormControl();
  optionsDisplayed: T[] = [];
  isOpen: boolean = false;

  constructor(private cd: ChangeDetectorRef) {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(300),
        takeUntilDestroyed(),
      ).subscribe(term => this.search(term));
  }

  ngOnInit() {
    if (this.entity !== null) {
      this.entity = this.options.find(
        currentOption => currentOption === this.entity
      );
    }
  }

  ngOnChanges() {
    this.optionsDisplayed = this.options;
  }

  search(value: string) {
    this.optionsDisplayed = this.options.filter(
      option => JSON.stringify(option, ['name', 'id'])
          .toLowerCase()
          .includes(value.toLowerCase()
      )
    );

    this.cd.detectChanges();
  }

  open(dropdown: TemplateRef<any>) {
    if (this.isOpen) {
      return;
    }

    this.isOpen = true;
    this.dropDownContainer.createEmbeddedView(dropdown, dropdown);
  }

  isActive(option: T): boolean {
    if (!this.entity) {
      return false;
    }
    return this.entity === option
  }

  select(option: T) {
    this.close();
    this.entity = option;
    this.selectChange.emit(option);
    this.selectControl.setValue(option);
  }

  close() {
    this.isOpen = false;
    this.searchControl.patchValue('');
    this.dropDownContainer.clear();
  }
}
