import { Component, Input } from '@angular/core';
import { Size } from './title.models';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-title',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './title.component.html',
  styleUrl: './title.component.sass'
})
export class TitleComponent {
  @Input() size: Size = 'large';

  get titleClasses(): string[] {
    return [
      'title',
      `title--${this.size}`,
    ];
  }
}
