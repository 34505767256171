export const environment = {
  name: 'dev',
  production: false,
  referenceApi: 'https://reference-api.staging.armis.tech',
  armisReferenceApi: 'https://armis-reference-api.staging.armis.tech',
  saasBackend: 'https://console-backend.staging.armis.tech',
  RefApiUsername: '',
  RefApiPassword: '',
  armisIdentity: {
    url: 'https://armis-identity.staging.armis.tech',
    realm: 'armis-clerks',
    clientId: 'back-office',
  },
};
