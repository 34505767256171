import { Component, Input } from '@angular/core';
import { Link } from './side-menu-navigation.model';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AsyncPipe, NgFor, NgIf, NgOptimizedImage } from '@angular/common';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';

@Component({
  selector: 'app-side-menu-navigation',
  templateUrl: './side-menu-navigation.component.html',
  styleUrls: ['./side-menu-navigation.component.sass'],
  standalone: true,
  imports: [NgFor, RouterLinkActive, RouterLink, NgOptimizedImage, AsyncPipe, NgIf]
})
export class SideMenuNavigationComponent {
  @Input() navigations: Link[];

  user: KeycloakProfile;

  constructor(private readonly kc: KeycloakService) {
    this.getUserinfo().catch(console.error);
  }

  private async getUserinfo() {
    this.user = await this.kc.loadUserProfile();
  }
}
