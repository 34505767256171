<div class="content-container">
  <app-side-menu-navigation
    [navigations]="navigations"
  ></app-side-menu-navigation>

  <div class="page-container">
    <div class="page-container-size">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
