import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { LoginResponse, User } from '../../user/model/user.model';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApisAuthService {
  referenceApiToken: string;

  isReferenceApiLogged: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private readonly http: HttpClient) {}

  isApisAuthenticated(): Observable<boolean> {
    return combineLatest([
      this.isReferenceApiLogged,
    ]).pipe(
      map(([isReferenceApiLogged]) => {
      return isReferenceApiLogged;
    }));
  }

  authenticateToApis(): void {
    this.oldReferenceLogin();
  }

  private oldReferenceLogin() {
    const user: User = {
      username: environment.RefApiUsername,
      password: environment.RefApiPassword,
    }

    this.http.post<LoginResponse>(environment.referenceApi + '/_login', user).subscribe((res: LoginResponse) => {
      this.referenceApiToken = res.token;
      this.isReferenceApiLogged.next(true);
    });
  }
}
