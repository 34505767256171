import { Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { BORoutes } from './back-office/back-office.route';

export const routes: Routes = [
  {
    path: '',
    children: BORoutes,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
