import { Component, Input } from '@angular/core';
import { Link, MenuNavigation } from '../side-menu-navigation/side-menu-navigation.model';
import { RouterOutlet } from '@angular/router';
import { SideMenuNavigationComponent } from '../side-menu-navigation/side-menu-navigation.component';

@Component({
    selector: 'app-main-page',
    templateUrl: './main-page.component.html',
    styleUrls: ['./main-page.component.sass'],
    standalone: true,
    imports: [SideMenuNavigationComponent, RouterOutlet]
})
export class MainPageComponent {
  @Input() navigations: Link[];
}
