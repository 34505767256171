import { APP_INITIALIZER, enableProdMode, importProvidersFrom } from '@angular/core';


import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializeKeycloak } from './app/init/keycloak-init.factory';
import { routes } from './app/app.route';
import { provideRouter, withComponentInputBinding, withHashLocation } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptorService } from './app/features/interceptor/auth.interceptor.service';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
      provideRouter(
        routes,
        withComponentInputBinding(),
        withHashLocation(),
      ),
      provideHttpClient(withInterceptorsFromDi()),
      importProvidersFrom(BrowserModule, BrowserAnimationsModule, KeycloakAngularModule),
      {
          provide: APP_INITIALIZER,
          useFactory: initializeKeycloak,
          multi: true,
          deps: [KeycloakService],
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptorService,
        multi: true,
      },
    ]
}).catch(err => console.error(err));
