import { Account } from '../../account/models/account.model';

export type CsvShop = {
  shopRef: string;
  name: string;
  city: string;
  street: string;
  postcode: string;
  latitude: number;
  longitude: number;
  /*## MANDATORY FOR GEO TOOL ##*/
  country: string;
  catchmentAreasType?: AreaType;
  catchmentAreasCodes?: string;
  /* ########################## */
  shopType?: string;
  contactUrl?: string;
}

export type Shop = {
  id?: number;
  version?: number;
  account?: Account;
  shopRef: string;
  name: string;
  openingHours?: string;
  contactUrl?: string;
  reachPotential?: number;
  recommendedBudget?: number;
  shopDetails: ShopDetails;
  shopAddress: ShopAddress;
  urlFriendly: boolean;
}

export enum AreaType {
  INSEE = 'INSEE',
  ZIP = 'ZIP',
  IRIS = 'IRIS',
  CENSAL = 'CENSAL',
}

export type ShopDetails = {
  /*###### SET BY GEO TOOL ######*/
  col_radius?: string;
  col_latitude?: string;
  col_longitude?: string;
  geo_segment_id_xandr?: string;
  geo_circles?: GeoCircle[];
  /* ########################### */
  catchment_areas_country: string; // equal to country
  catchment_areas_type?: AreaType;
  catchment_areas_codes?: any[];
  shop_type?: string;
  business_area?: string;
}

export type ShopAddress = {
  street: string;
  short_street?: string;
  postcode: string;
  city: string;
  country: string;
  latitude: string;
  longitude: string;
}

export interface GeoCircle {
  lat: number;
  long: number;
  radius: number;
}
