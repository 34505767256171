import { Component } from '@angular/core';
import { MainPageComponent } from '../../common/layout/main-page/main-page.component';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-workflow',
    templateUrl: './workflow.component.html',
    styleUrls: ['./workflow.component.sass'],
    standalone: true,
  imports: [MainPageComponent, RouterOutlet]
})
export class WorkflowComponent {}
