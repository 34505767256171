<div class="input-select" [ngClass]="selectControl?.invalid && !selectControl?.untouched ? 'input-error' : ''" #selectInput>
  <button [disabled]="disabled" (click)="open(dropdown)">

    <ng-template [ngIf]="!isOpen" [ngIfElse]="searchTpl">
      <ng-container *ngIf="entity === null"><em class="fas fa-chevron-down input-select-logo"></em>{{ label }}</ng-container>
      <ng-container *ngIf="entity !== null">
        <ng-template *ngTemplateOutlet="template; context: {$implicit: entity}"></ng-template>
      </ng-container>
    </ng-template>

    <ng-template #searchTpl>
      <input [formControl]="searchControl" class="input-select-search" placeholder="Search...">
    </ng-template>

  </button>

  <div class="input-select-options" #dropDownContainer></div>
</div>

<ng-template #dropdown>
  <div class="select-menu">

    <cdk-virtual-scroll-viewport itemSize="16">

      <div *ngIf="!optionsDisplayed?.length">No results found...</div>

      <div
        class="select-menu-options"
        *cdkVirtualFor="let option of optionsDisplayed"
        [class.active]="isActive(option)"
        (click)="select(option)"
        (keydown)="select(option)"
      >

        <ng-template [ngIf]="!template">{{option}}</ng-template>

        <ng-template *ngTemplateOutlet="template; context: {$implicit: option}"></ng-template>
      </div>

    </cdk-virtual-scroll-viewport>

  </div>
</ng-template>
