import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ApisAuthService } from '../features/apis-auth/service/apis-auth.service';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { RouterOutlet } from '@angular/router';
import { AsyncPipe, NgIf } from '@angular/common';
import { HeaderComponent } from '../common/layout/header/header.component';
import { Observable, of } from 'rxjs';
import { ErrorsService } from '../features/errors/services/errors.service';
import { errorsMock } from '../features/errors/models/error.model.mock';
import { MainPageComponent } from '../common/layout/main-page/main-page.component';
import { BORoutes } from './back-office.route';
import { backOfficeNavigations } from './back-office.navigations';

@Component({
    selector: 'app-back-office',
    templateUrl: './back-office.component.html',
    styleUrls: ['./back-office.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
  imports: [
    HeaderComponent,
    NgIf,
    RouterOutlet,
    AsyncPipe,
    MainPageComponent,
  ],
})
export class BackOfficeComponent implements OnInit {
  isApisLogged: Observable<boolean>;
  keycloakAuth: boolean = false;
  errors: Observable<Error[]>;
  user: KeycloakProfile;

  constructor(
    private readonly authService: ApisAuthService,
    private readonly keycloakService: KeycloakService,
    private readonly errorsService: ErrorsService,
  ) {}

  ngOnInit() {
    this.isApisLogged = of(true);
    // this.isApisLogged = this.authService.isApisAuthenticated()
    // this.authService.authenticateToApis();
    this.errors = this.errorsService.errorsObservable;

    this.keycloakAuth = this.keycloakService.isLoggedIn();
    this.keycloakService.loadUserProfile().then((user) => {
      this.user = user;
    });
  }

  protected readonly backOfficeNavigations = backOfficeNavigations;
}
