import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { WorkflowStepComponent } from '../workflow-step/workflow-step.component';
import { NgClass, NgIf } from '@angular/common';
import { ButtonComponent } from '../layout/button/button.component';
import { ParagraphComponent } from '../layout/paragraph/paragraph.component';
import { LoaderComponent } from '../layout/loader/loader.component';

@Component({
  selector: 'app-workflow-manager',
  standalone: true,
  imports: [
    NgIf,
    ButtonComponent,
    NgClass,
    ParagraphComponent,
    LoaderComponent
  ],
  templateUrl: './workflow-manager.component.html',
  styleUrl: './workflow-manager.component.sass'
})
export class WorkflowManagerComponent implements AfterContentInit {
  @Input() errors: string[] = [];
  @Input() notifications: string[] = [];
  @Input() loading: boolean = false;

  @Output() validate: EventEmitter<void> = new EventEmitter();

  showAllErrors: boolean = false;
  showAllNotifications: boolean = false;

  @ContentChildren(WorkflowStepComponent) viewSteps!: QueryList<WorkflowStepComponent>;

  selectedStep: WorkflowStepComponent = null;
  nextMandatoryStep: WorkflowStepComponent = null;

  get stepsMaxIndex(): number {
    return this.viewSteps.length - 1;
  }

  get nextButtonDisabled(): boolean {
    return !this.selectedStep?.form.valid;
  }

  get selectedStepIndex(): number {
    return this.viewSteps.toArray().indexOf(this.selectedStep);
  }

  ngAfterContentInit() {
    if (this.viewSteps?.length === 0) {
      return;
    }

    this.viewSteps.first.hidden = false;
    this.selectedStep = this.viewSteps.first;

    if (this.viewSteps.length > 1) {
      this.nextMandatoryStep = this.viewSteps.first;
    }
  }

  navigate(index: number) {
    if (!this.canNavigate(index)) {
      return;
    }

    this.viewSteps.toArray().forEach((step, i) => {
      step.hidden = i !== index;
    });

    this.selectedStep = this.viewSteps.get(index);

    if (this.getStepIndex(this.nextMandatoryStep) < index) {
      this.nextMandatoryStep = this.selectedStep;
    }
  }

  previousStep() {
    const index = this.getStepIndex(this.selectedStep);
    this.navigate(index - 1);
  }

  nextStep() {
    const index = this.getStepIndex(this.selectedStep);
    this.navigate(index + 1);
  }

  validateWorkflow() {
    this.validate.emit();
  }

  canNavigate(index: number) {
    const isValid = this.viewSteps.get(index).form.valid;
    const isFirst = index === 0;
    const isNext = this.viewSteps.get(index - 1)?.form.valid;

    return isValid || isFirst || isNext;
  }

  getStepIndex(step: WorkflowStepComponent): number {
    return this.viewSteps.toArray().indexOf(step);
  }

  onErrorCLicked() {
    this.showAllErrors = !this.showAllErrors;
  }

  onNotificationCLicked() {
    this.showAllNotifications = !this.showAllNotifications;
  }

  stepStatus(step: WorkflowStepComponent) {
    if (step.form.valid) {
      return 'done';
    } else if(step.isFailed) {
      return 'error';
    } else {
      return 'ongoing';
    }
  }

  get isWorkflowFinished(): boolean {
    return this.viewSteps.reduce((acc, step) => acc && step.form.valid, true);
  }
}
