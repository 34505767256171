import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { CsvHelper } from './input-csv.parser';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-input-csv',
  standalone: true,
  imports: [
    ReactiveFormsModule
  ],
  templateUrl: './input-csv.component.html',
  styleUrl: './input-csv.component.sass'
})
export class InputCsvComponent<T, S> {
  @Input() parser: CsvHelper<T[], S[]>;
  @Input() csvControl: FormControl<S[]> = new FormControl([]);
  @Input() disabled: boolean = false;

  @Output() errors: EventEmitter<string[]> = new EventEmitter();

  constructor(
    private readonly csvParser: NgxCsvParser,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  uploadEntities(files: any) {
    const csv = files.target.files[0];

    this.csvParser.parse(csv, { header: true, delimiter: ',', encoding: 'utf8' })
      .pipe(
        map((result) => {
          if (result instanceof NgxCSVParserError) {
            this.errors.emit([result.message]);
            return null;
          }
          return result as T[];
        }),
        map((result: T[]) => this.parser.parse(result)),
      ).subscribe((entities) => {
        this.csvControl.patchValue(entities);
        this.changeDetectorRef.markForCheck();
      });
  }
}
