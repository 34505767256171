import { Routes } from '@angular/router';
import { WorkflowComponent } from './workflow.component';
import { IntegrateShopsComponent } from './pages/integrate-shops/integrate-shops.component';

export const workflowsRoutes: Routes = [
  {
    path: '',
    component: WorkflowComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'integrate-shops',
      },
      {
        path: 'integrate-shops',
        component: IntegrateShopsComponent,
      },
    ],
  },
];
