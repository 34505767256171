import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-download-link',
  standalone: true,
  imports: [],
  templateUrl: './download-link.component.html',
  styleUrl: './download-link.component.sass'
})
export class DownloadLinkComponent {
  @Input() file!: string;
  @Input() name!: string;
}
