import { Component } from '@angular/core';

import { environment } from '../environments/environment';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.sass'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent {
  constructor() {
    console.log(environment.name + ' : ' + environment.production);
  }
}
