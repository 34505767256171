import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { AccountService } from '../../services/account.service';
import { map, Observable } from 'rxjs';
import { Account } from '../../models/account.model';
import { AsyncPipe } from '@angular/common';
import { FormControl, FormsModule } from '@angular/forms';
import { MatOption, MatSelect } from '@angular/material/select';
import { InputSelectComponent } from '../../../../common/inputs/input-select/input-select.component';

@Component({
  selector: 'app-account-selector',
  standalone: true,
  imports: [
    MatFormField,
    MatInput,
    AsyncPipe,
    FormsModule,
    MatSelect,
    MatOption,
    InputSelectComponent,
  ],
  templateUrl: './account-selector.component.html',
  styleUrl: './account-selector.component.sass'
})
export class AccountSelectorComponent implements OnInit {
  accounts: Observable<Account[]>;

  @Input() disabled: boolean = false;
  @Input() selectControl: FormControl<Account>;

  @Output() accountEmitter = new EventEmitter<Account>();

  constructor(
    private readonly accountService: AccountService,
  ) {}

  ngOnInit() {
    this.accounts = this.accountService.getAccounts().pipe(
      map((accounts: Account[]) => accounts.slice().sort((a, b) => a.name.localeCompare(b.name))),
    );
  }

  onChange(account: Account) {
    this.accountEmitter.emit(account);
  }
}
