<div class="shop-summary" *ngIf="!!shop">
  <div class="shop-summary-title">
    <app-title size="medium">{{ shop.name }} - {{ shop.shopRef }}</app-title>
    <app-tag type="danger" *ngIf="!!error">{{ error }}</app-tag>
  </div>
  <div class="shop-summary-details">
    <div>
      <app-title size="small">Address</app-title>

      <app-label [inline]="false">Country</app-label>
      <app-paragraph>{{ shop.shopAddress.country }}</app-paragraph>

      <app-label [inline]="false">Address</app-label>
      <app-paragraph>{{ shop.shopAddress.street }} - {{ shop.shopAddress.city }} ({{ shop.shopAddress.postcode }})</app-paragraph>

      <app-label [inline]="false">Latitude - Longitude</app-label>
      <app-paragraph>{{ shop.shopAddress.latitude }} - {{ shop.shopAddress.longitude }}</app-paragraph>
    </div>
    <div>
      <app-title size="small">Details</app-title>

      <app-label [inline]="false">Catchment Area Country</app-label>
      <app-paragraph>{{ shop.shopDetails.catchment_areas_country }}</app-paragraph>

      <app-label [inline]="false">Catchment Area Type</app-label>
      <app-paragraph>{{ shop.shopDetails.catchment_areas_type }}</app-paragraph>

      <app-label [inline]="false">Catchment Area Codes</app-label>
      <app-paragraph>{{ shop.shopDetails.catchment_areas_codes }}</app-paragraph>

      <ng-container *ngIf="shop.shopDetails.shop_type">
        <app-label [inline]="false">Shop Type</app-label>
        <app-paragraph>{{ shop.shopDetails.shop_type }}</app-paragraph>
      </ng-container>

      <ng-container *ngIf="shop.contactUrl">
        <app-label [inline]="false">Contact</app-label>
        <app-paragraph>{{ shop.contactUrl }}</app-paragraph>
    </ng-container>
    </div>
  </div>
</div>
