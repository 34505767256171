import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AreaType, Shop } from './shop.model';

export function shopMandatoryFields(): ValidatorFn {
  return (control: AbstractControl<Shop[]>): ValidationErrors => {
    const errors: string[] = []

    if (control.value === null) {
      return null;
    }

    control.value.forEach((shop, index) => {
      const error: string[] = []
      if (!shop.shopRef) {
        error.push('shop ref');
      }

      if (!shop.name) {
        error.push('name');
      }

      if (!shop.shopAddress.city) {
        error.push('city');
      }

      if (!shop.shopAddress.postcode) {
        error.push('postcode');
      }

      if (!shop.shopAddress.latitude) {
        error.push('latitude');
      }

      if (!shop.shopAddress.longitude) {
        error.push('longitude');
      }

      if (!shop.shopAddress.country) {
        error.push('country');
      }

      if (!shop.shopAddress.street) {
        error.push('street');
      }

      if (error.length > 0) {
        // + 1 because array index start from 0 and + 1 because of the file header
        if (error.length > 1) {
          const message = error.join(', ').concat(` are mandatory fields on line ${index + 2}`);
          errors.push(message);
        } else {
          const message = error.pop().concat(` is a mandatory field on line ${index + 2}`);
          errors.push(message);
        }
      }
    });

    if (errors.length === 0) {
      return null;
    }
    return { shopMandatoryFields: errors };
  };
}

export function shopConformityFields(): ValidatorFn {
  return (control: AbstractControl<Shop[]>): ValidationErrors => {
    const errors: string[] = []

    if (control.value === null) {
      return null;
    }

    const tempNames: string[] = []
    const tempShopRefs: string[] = []

    control.value.forEach((shop, index) => {
      const csvIndex = index +2;

      if (tempNames.includes(shop.name) && !!shop.name) {
        errors.push('shop name already exists in the file at line ' + (csvIndex));
      }
      tempNames.push(shop.name);

      if (tempShopRefs.includes(shop.shopRef) && !!shop.shopRef) {
        errors.push('shop ref already exists in the file at line ' + (csvIndex));
      }
      tempShopRefs.push(shop.shopRef);

      if (!!shop.shopDetails.catchment_areas_type && !Object.values(AreaType).includes(shop.shopDetails.catchment_areas_type)) {
        errors.push('catchment area type is not a known type at line ' + (csvIndex));
      }

      if (!!shop.shopDetails.catchment_areas_type && shop.shopDetails.catchment_areas_codes === undefined) {
        errors.push('catchment area codes is missing at line ' + (csvIndex));
      }

      if (!shop.shopDetails.catchment_areas_type && !!shop.shopDetails.catchment_areas_codes) {
        errors.push('catchment area type is missing at line ' + (csvIndex));
      }

      if (shop.shopDetails.catchment_areas_codes === null) {
        errors.push('catchment area codes should be an array of string (wrong format) at line ' + (csvIndex));
      }

      if (!!shop.shopDetails.catchment_areas_type && !!shop.shopDetails.catchment_areas_codes && shop.shopDetails.catchment_areas_codes.length === 0) {
        errors.push('catchment area codes should not be empty at line ' + (csvIndex));
      }
    });

    if (errors.length === 0) {
      return null;
    }
    return { shopConformityFields: errors };
  };
}
