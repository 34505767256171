import { Component, Input } from '@angular/core';
import { Shop } from '../../models/shop.model';
import { AddInputComponent } from '../../../../common/inputs/add-input/add-input.component';
import { InputObjectComponent } from '../../../../common/inputs/input-object/input-object.component';
import { KeyValuePipe, NgIf } from '@angular/common';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from '@angular/material/expansion';
import { ParagraphComponent } from '../../../../common/layout/paragraph/paragraph.component';
import { TitleComponent } from '../../../../common/layout/title/title.component';
import { LabelComponent } from '../../../../common/layout/label/label.component';
import { TagComponent } from '../../../../common/layout/tag/tag.component';

@Component({
  selector: 'app-shop-summary',
  standalone: true,
  imports: [
    AddInputComponent,
    InputObjectComponent,
    KeyValuePipe,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    NgIf,
    ParagraphComponent,
    TitleComponent,
    LabelComponent,
    TagComponent
  ],
  templateUrl: './shop-summary.component.html',
  styleUrl: './shop-summary.component.sass'
})
export class ShopSummaryComponent {
  @Input() shop: Shop;
  @Input() error: string;
}
