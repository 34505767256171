import { Component, Input, OnInit } from '@angular/core';
import { Shop } from '../../models/shop.model';
import { NgForOf, NgIf } from '@angular/common';
import { ShopSummaryComponent } from '../shop-summary/shop-summary.component';
import { RowResult } from '../../../bulk/bulk.model';
import { ParagraphComponent } from '../../../../common/layout/paragraph/paragraph.component';

@Component({
  selector: 'app-shops-summary',
  standalone: true,
  imports: [
    NgIf,
    ShopSummaryComponent,
    NgForOf,
    ParagraphComponent,
  ],
  templateUrl: './shops-summary.component.html',
  styleUrl: './shops-summary.component.sass'
})
export class ShopsSummaryComponent {
  @Input() rowShops: RowResult<Shop>[];
}
