<div class="side-menu">
  <a routerLink="./" class="logo">
    <img ngSrc="../../../../assets/images/logo.png" alt="armis" fill priority>
  </a>

  <nav class="navigation">
    <div class="navigation-link" *ngFor="let link of navigations">
      <a
        [routerLink]="link.navigation"
        routerLinkActive="active"
      >
        <em [class]="'fa-solid fa-' + link.icon"></em>
        {{ link.name }}
      </a>
    </div>
  </nav>

  <div class="user-infos" *ngIf="!!user">
    <p>{{ user?.firstName }} {{ user?.lastName }}</p>
  </div>
</div>
