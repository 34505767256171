import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { errorsMock } from '../models/error.model.mock';

@Injectable({
  providedIn: 'root'
})
export class ErrorsService {
  private errors: BehaviorSubject<Error[]> = new BehaviorSubject([]);

  get errorsObservable() {
    return this.errors.asObservable();
  }

  add(error: Error) {
    this.errors.next([...this.errors.value, error]);
  }

  clear() {
    this.errors.next([]);
  }

  remove(index: number) {
    const errors = this.errors.value;

    errors.splice(index, 1);

    this.errors.next(errors);
  }
}
