<div class="workflow-manager">
  <app-loader *ngIf="loading"></app-loader>

  <div
    *ngIf="notifications.length > 0"
    [ngClass]="showAllNotifications ? 'detailed-notifications' : 'simple-notifications'"
    (click)="onNotificationCLicked()"
    (keydown)="onNotificationCLicked()"
    class="workflow-manager-notifications">

    <div class="workflow-manager-notifications-tooltip" *ngIf="notifications.length > 1">{{ notifications.length }}</div>

    @for(notification of notifications; track notifications; let isLast = $last) {
      <app-paragraph [spacing]="!isLast || notification.length > 1">{{ notification }}</app-paragraph>
    }
  </div>

  <div
    *ngIf="errors.length > 0"
    [ngClass]="showAllErrors ? 'detailed-errors' : 'simple-errors'"
    (click)="onErrorCLicked()"
    (keydown)="onErrorCLicked()"
    class="workflow-manager-errors">

    <div class="workflow-manager-errors-tooltip" *ngIf="errors.length > 1">{{ errors.length }}</div>

    @for(error of errors; track errors; let isLast = $last) {
      <app-paragraph [spacing]="!isLast || errors.length > 1">{{ error }}</app-paragraph>
    }
  </div>

  <div class="workflow-manager-header">

    @for(step of viewSteps.toArray(); track $index; let isFirst = $first ) {
      <div
        *ngIf="!isFirst"
        class="workflow-manager-header-step-link"
        [ngClass]="getStepIndex(nextMandatoryStep) >= $index ? 'done' : ''"
      ></div>
      <div class="workflow-manager-header-step">
        <div (click)="navigate($index)"
          (keydown)="navigate($index)"
          [ngClass]="[
            stepStatus(step),
            step === selectedStep ? 'active' : 'not-active',
            canNavigate($index) ? 'pointer' : 'forbidden'
          ]"
        >
          <em *ngIf="step.form.valid" class="fa-solid fa-check"></em>
          <em *ngIf="step.isFailed" class="fa-solid fa-xmark"></em>
        </div>
      </div>
    }

  </div>

  <div class="workflow-manager-content">
    <ng-content></ng-content>
  </div>

  <div class="workflow-manager-footer">
    <app-button type="neutral" *ngIf="selectedStepIndex > 0 && selectedStepIndex <= stepsMaxIndex" (onClick)="previousStep()">Previous</app-button>
    <app-button type="secondary" [disabled]="nextButtonDisabled" *ngIf="selectedStepIndex < stepsMaxIndex" (onClick)="nextStep()">Next</app-button>
    <app-button type="primary" [disabled]="isWorkflowFinished" *ngIf="selectedStepIndex === stepsMaxIndex" (onClick)="validateWorkflow()">Validate</app-button>
  </div>
</div>
