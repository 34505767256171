export class CSVHelper {
  static jsonArrayToCSV(data: string[] | any[]): Blob {
    const replacer = (key: string, value: string) => value === null ? '' : value;
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');
    return new Blob([csvArray], {type: 'text/csv' });
  }
}
