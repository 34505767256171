import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-label',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './label.component.html',
  styleUrl: './label.component.sass'
})
export class LabelComponent {
  @Input() linkedId: string = '';
  @Input() inline: boolean = true;
}
