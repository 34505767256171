<app-title size="large">Shops integration</app-title>
<app-sub-title size="large">Here you can upload shops for a specific account</app-sub-title>

<app-card>
  <app-workflow-manager [errors]="errors" [notifications]="customNotifications" [loading]="loading" (validate)="onValidate()">
    <app-workflow-step [form]="firstForm">
      <app-paragraph>Integrate one or many shops to an account</app-paragraph>
      <app-label>Select an account</app-label>
      <app-account-selector [selectControl]="account" [disabled]="isInputDisabled"></app-account-selector>
    </app-workflow-step>

    <app-workflow-step [form]="secondForm">
      <app-paragraph>Load shop for the account
        <ng-container *ngIf="account.value != null"> {{ account.value.name }}</ng-container>
      </app-paragraph>
      <app-label [inline]="false">Insert file (.csv file) - <app-download-link [file]="csvTemplate" name="shop-import-template" type="csv/text">get the template</app-download-link></app-label>
      <app-input-csv (errors)="handleErrors($event)" [csvControl]="shops" [parser]="shopHelper" [disabled]="isInputDisabled"></app-input-csv>
    </app-workflow-step>

    <app-workflow-step [form]="thirdForm" [failed]="isWorkflowFailed">
      <ng-container *ngIf="!finalShops.value && customErrors.length === 0">
        <app-paragraph>Your shops are ready to be created for the account
          <ng-container *ngIf="account.value != null"> {{ account.value.name }}</ng-container>
        </app-paragraph>
        <app-paragraph>Push the validate button to launch the creation</app-paragraph>
      </ng-container>

      <ng-container *ngIf="finalShops.value?.length > 0 && customErrors.length === 0">
        <app-paragraph>All your shops were successfully created for the account
          <ng-container *ngIf="account.value != null"> {{ account.value.name }}</ng-container>
        </app-paragraph>
      </ng-container>

      <ng-container *ngIf="finalShops.value?.length > 0 && customErrors.length > 0">
        <app-paragraph>Shops creation partially succeed for the account
          <ng-container *ngIf="account.value != null"> {{ account.value.name }}</ng-container>
        </app-paragraph>
        <app-paragraph>Here is the details of the shops who failed</app-paragraph>
        <app-shops-summary [rowShops]="failedShops"></app-shops-summary>
      </ng-container>

      <ng-container *ngIf="!finalShops.value && customErrors.length > 0">
        <app-paragraph>Shops creation is complete disaster for the account
          <ng-container *ngIf="account.value != null"> {{ account.value.name }}</ng-container>
        </app-paragraph>
        <app-paragraph>Here is the details of the shops who failed</app-paragraph>
        <app-shops-summary [rowShops]="failedShops"></app-shops-summary>
      </ng-container>
    </app-workflow-step>
  </app-workflow-manager>
</app-card>
