import { CsvShop } from './shop.model';

export const emptyCsvShop: CsvShop = {
  name: null,
  shopRef: null,
  country: null,
  city: null,
  street: null,
  postcode: null,
  latitude: null,
  longitude: null,
  catchmentAreasCodes: null,
  catchmentAreasType: null,
  contactUrl: 'optional',
  shopType: 'optional',
}
