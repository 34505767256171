import { Routes } from '@angular/router';
import { BackOfficeComponent } from './back-office.component';
import { workflowsRoutes } from './workflows/workflows.route';
import { LandingComponent } from './landing/landing.component';

export const BORoutes: Routes = [
  {
    path: '',
    component: BackOfficeComponent,
    children: [
      {
        path: '',
        component: LandingComponent,
      },
      {
        path: 'dashboard',
        children: [],
      },
      {
        path: 'workflows', children: workflowsRoutes,
      },
      {
        path: 'entities',
        children: [],
      },
    ],
  },
];
