import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Type } from './button.model';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './button.component.html',
  styleUrl: './button.component.sass'
})
export class ButtonComponent {
  @Input() disabled: boolean = false;
  @Input() type: Type = 'neutral';

  @Output() onClick: EventEmitter<void> = new EventEmitter();

  click() {
    this.onClick.emit();
  }
}
