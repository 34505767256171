import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { catchError, Observable, of } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { Shop } from '../models/shop.model';
import { BulkErrorResponse, BulkResponse } from '../../bulk/bulk.model';

@Injectable({
  providedIn: 'root'
})
export class ShopService {
  constructor(private readonly http: HttpClient) {}

  getShop(shopId: string): Observable<Shop> {
    return this.http.get<Shop>(`${environment.armisReferenceApi}/shop/${shopId}`);
  }

  getShops(): Observable<Shop[]> {
    return this.http.get<Shop[]>(`${environment.armisReferenceApi}/shops`);
  }

  postShops(shops: Shop[]): Observable<BulkResponse<Shop>> {
    return this.http.post<BulkResponse<Shop>>(`${environment.armisReferenceApi}/shops`, { shops }).pipe(
      catchError((error: BulkErrorResponse<Shop>) => {
        return of(error.error);
      })
    );
  }
}
