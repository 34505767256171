import { AreaType, CsvShop, Shop } from '../models/shop.model';
import { CsvHelper } from '../../../common/inputs/input-csv/input-csv.parser';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShopHelper implements CsvHelper<CsvShop[], Shop[]> {
  parse(csv: CsvShop[]): Shop[] {
    return csv.map((shop: CsvShop) => ({
      shopRef: shop.shopRef.trim(),
      name: shop.name.trim(),
      urlFriendly: true,
      contactUrl: shop.contactUrl?.trim(),
      shopDetails: {
        shop_type: shop.shopType?.trim(),
        catchment_areas_country: shop.country.trim(),
        catchment_areas_codes: this.trimFromArray(shop.catchmentAreasCodes),
        catchment_areas_type: shop.catchmentAreasType?.trim() as AreaType,
      },
      shopAddress: {
        city: shop.city.trim(),
        latitude: shop.latitude.toString().trim(),
        longitude: shop.longitude.toString().trim(),
        postcode: shop.postcode.trim(),
        street: shop.street.trim(),
        country: shop.country.trim(),
      },
    }));
  }

  private trimFromArray(stringArray: string): string[] {
    if (!!stringArray) {
      try {
        const array: string[] = JSON.parse(stringArray);
        if (Array.isArray(array)) {
          return Array.from(array)?.map((item) => item.trim());
        }
      } catch (error) {
        return null;
      }
    }

    return undefined;
  }
}
