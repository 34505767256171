import { ChangeDetectorRef, Component } from '@angular/core';
import { TitleComponent } from '../../../../common/layout/title/title.component';
import { SubTitleComponent } from '../../../../common/layout/sub-title/sub-title.component';
import { CardComponent } from '../../../../common/layout/card/card.component';
import {
  AccountSelectorComponent
} from '../../../../features/account/components/account-selector/account-selector.component';
import { Account } from '../../../../features/account/models/account.model';
import { ParagraphComponent } from '../../../../common/layout/paragraph/paragraph.component';
import { LabelComponent } from '../../../../common/layout/label/label.component';
import { WorkflowManagerComponent } from '../../../../common/workflow-manager/workflow-manager.component';
import { WorkflowStepComponent } from '../../../../common/workflow-step/workflow-step.component';
import { FormControl, FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Shop } from '../../../../features/shop/models/shop.model';
import { JsonPipe, NgIf } from '@angular/common';
import { InputCsvComponent } from '../../../../common/inputs/input-csv/input-csv.component';
import { ShopHelper } from '../../../../features/shop/services/shop.helper';
import { shopConformityFields, shopMandatoryFields } from '../../../../features/shop/models/shop.validator';
import { ShopService } from '../../../../features/shop/services/shop.service';
import { BulkResponse, RowResult } from '../../../../features/bulk/bulk.model';
import { ShopsSummaryComponent } from '../../../../features/shop/components/shops-summary/shops-summary.component';
import { ButtonComponent } from '../../../../common/layout/button/button.component';
import { CSVHelper } from '../../../../common/inputs/input-csv/csv.helper';
import { emptyCsvShop } from '../../../../features/shop/models/shop.model.mock';
import { DownloadLinkComponent } from '../../../../common/layout/download-link/download-link.component';

@Component({
  selector: 'app-integrate-shops',
  standalone: true,
  imports: [
    TitleComponent,
    SubTitleComponent,
    CardComponent,
    AccountSelectorComponent,
    ParagraphComponent,
    LabelComponent,
    WorkflowManagerComponent,
    WorkflowStepComponent,
    NgIf,
    ReactiveFormsModule,
    InputCsvComponent,
    JsonPipe,
    ShopsSummaryComponent,
    ButtonComponent,
    DownloadLinkComponent,
  ],
  templateUrl: './integrate-shops.component.html',
  styleUrl: './integrate-shops.component.sass'
})
export class IntegrateShopsComponent {
  account: FormControl<Account> = this.fb.control(null, Validators.required);
  shops: FormControl<Shop[]> = this.fb.control(null, [Validators.required, shopMandatoryFields(), shopConformityFields()]);
  finalShops: FormControl<Shop[]> = this.fb.control(null, [Validators.required]);

  firstForm: FormGroup = this.fb.group({
    account: this.account,
  });

  secondForm: FormGroup = this.fb.group({
    shops: this.shops,
  });

  thirdForm: FormGroup = this.fb.group({
    finalStep: this.finalShops,
  });

  forms: FormGroup = this.fb.group([this.firstForm.controls, this.secondForm.controls, this.thirdForm.controls]);

  failedShops: RowResult<Shop>[];

  csvTemplate: string = URL.createObjectURL(CSVHelper.jsonArrayToCSV([emptyCsvShop]));

  customErrors: string[] = [];
  customNotifications: string[] = [];

  loading: boolean = false;
  isWorkflowFailed: boolean = false;

  get errors(): string[] {
    return []
      .concat(this.customErrors)
      .concat(this.shops.getError('shopMandatoryFields'))
      .concat(this.shops.getError('shopConformityFields'))
      .filter((error) => !!error);
  };

  constructor(
    private readonly fb: NonNullableFormBuilder,
    readonly shopHelper: ShopHelper,
    readonly shopService: ShopService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  handleErrors(errors: string[]) {
    this.customErrors.push(...errors);
  }

  onValidate() {
    this.loading = true;

    const finalShops = this.shops.value.map(shop => {
      shop.account = this.account.value;
      return shop;
    });

    this.shopService.postShops(finalShops).subscribe((result: BulkResponse<Shop>) => {
      if (result.errors.length > 0) {
        this.customErrors.push(`There is/are ${result.errors.length} shops that had not been added to the account ${this.account.value.name}`);
        this.failedShops = result.errors;
        this.isWorkflowFailed = true;
      }

      if (result.missingMandatoryFields.length > 0) {
        this.customErrors.push(`There is/are ${result.missingMandatoryFields.length} shops that miss mandatory fields for the account`);
        this.isWorkflowFailed = true;
      }

      if (result.created.length > 0) {
        this.finalShops.setValue(result.created);
        this.customNotifications.push(`There is/are ${result.created.length} correctly added to the account ${this.account.value.name}`);
        this.isWorkflowFailed = false;
      }

      // Lifecycle change is not catch in subscription
      this.loading = false;
      this.changeDetectorRef.detectChanges();
    });
  }

  get isInputDisabled(): boolean {
    return this.finalShops.value?.length > 0;
  }
}
