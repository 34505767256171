import { Component } from '@angular/core';
import { TitleComponent } from '../../common/layout/title/title.component';
import { ParagraphComponent } from '../../common/layout/paragraph/paragraph.component';

@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [
    TitleComponent,
    ParagraphComponent
  ],
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.sass'
})
export class LandingComponent {

}
